/* You can add global styles to this file, and also import other style files */
@import 'variables/variables';
@import 'modal';
@import 'form';
@import 'typography';
@import 'button';
@import 'checkbox';
@import 'filter';
@import 'bootstrap-extend';
@import 'card-table';
@import 'form-field';
@import '@angular/cdk/overlay-prebuilt.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import "ngx-ui-switch/ui-switch.component.css";
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/scale.css';
@import 'table';
@import 'switch.override';
@import 'tippy.override';
@import 'toast.override';
@import 'ngselect.override';
@import 'datepicker.override';
@import 'bulk-edit-bar';

html {
  font-size: $base-font-size;
  height: 100%;
}

body {
  font-family: $font-family-primary;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  margin: 0;
  height: 100%;
}

.bordered {
  border: 1px solid black;
  padding: calculate-rem(10px);
}

.error-message {
  color: #de5a5a;
  height: calculate-rem(19px);
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

app-key-value-wrapper {
  display: block;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.pointer-events-auto {
  pointer-events: auto;
}
