$checkbox-square-size: 20px;
$checkbox-checked-bg-color: #2196f3;

@mixin custom-checkbox-radio {
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: calc($checkbox-square-size + 5px);
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $checkbox-checked-bg-color;
    }
  }

  .checkmark {
    position: absolute;
    height: $checkbox-square-size;
    width: $checkbox-square-size;
    top: 2px;
    left: 0;
    border: 1px solid lightgrey;
    background-color: white;
  }
}

.custom-checkbox {
  @include custom-checkbox-radio;
}

.custom-radio {
  @include custom-checkbox-radio;

  .checkmark {
    border-radius: calc($checkbox-square-size / 2);
  }
}
