@import 'functions/transitions.functions';
@import 'variables/text-field.variables';

$select-input-padding: calculate-rem(15px);

.base-select-style {
  background-color: $white;
  border: 1px solid $blue-40;
  border-radius: $border-radius;
  min-height: $text-field-height;
  height: $text-field-height;
  box-shadow: none;
  transition: apply-input-field-focus-animation(box-shadow);
}

.base-select-input {
  color: $black-100;
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: $base-font-weight;
  font-size: calculate-rem(13px);
  line-height: calculate-rem(18px);
  caret-color: $vivid-blue-100;
}

.transparent-select-styles {
  background-color: transparent;
  border: none;
  min-height: $text-field-height;
  height: $text-field-height;
  box-shadow: none;
  cursor: pointer;
}

.transparent-select-input {
  background-color: transparent;
  border: none;
  color: $white;
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: 700;
  line-height: calculate-rem(19px);
}

.base-dropdown-panel {
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: $base-font-weight;
  font-size: calculate-rem(13px);
  background: $white;
  border: 1px solid $blue-20;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius;
  overflow-x: hidden;
  line-height: 1.8em;

  &.ng-select-bottom {
    margin-top: calculate-rem(4px);
  }

  .ng-dropdown-panel-items {
    .ng-option {
      &.ng-option-marked,
      &.ng-option-selected {
        background-color: $blue-6;
      }

      &.ng-option-disabled {
        cursor: not-allowed;
        color: $black-60;
      }
    }
  }
}

.ng-select.custom-ng-select {
  margin-top: calculate-rem(8px);

  &.ng-select-multiple {
    .ng-select-container {
      @extend .base-select-style;

      .ng-value-container {
        height: 100%;
        display: flex;
        align-items: center;
        @extend .base-select-input;

        .ng-value-label {
          padding-left: calc(#{$select-input-padding} - 10px);
        }

        .ng-input {
          padding-left: $select-input-padding;

          > input {
            @extend .base-select-input;
          }
        }

        .ng-placeholder {
          @extend .base-select-input;
        }
      }

      .ng-arrow {
        border-color: $black-100 transparent transparent;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      @extend .base-select-style;

      .ng-clear-wrapper {
        color: $blue-40;
        text-align: center;

        &:hover {
          .ng-clear {
            color: $blue-40;
          }
        }

        .ng-clear {
          line-height: unset;
          vertical-align: middle;
        }
      }

      .ng-value-container {
        height: 100%;
        display: flex;
        align-items: center;
        @extend .base-select-input;

        .ng-value-label {
          padding-left: calc(#{$select-input-padding} - 10px);
        }

        .ng-placeholder {
          @extend .base-select-input;

          padding-left: calc(#{$select-input-padding} - 10px);
          color: $black-60;
        }

        .ng-input {
          top: 50%;
          transform: translateY(-50%);
          padding-left: $select-input-padding;

          > input {
            font-size: calculate-rem(13px);
            @extend .base-select-input;
          }
        }
      }

      .ng-arrow {
        border-color: $black-100 transparent transparent;
      }
    }
  }

  &.ng-select-disabled {
    > .ng-select-container {
      background-color: $blue-3;

      .ng-arrow {
        border-color: transparent;
      }
    }
  }

  &.ng-select-focused {
    > .ng-select-container {
      border-color: $vivid-blue-100;
      box-shadow: $text-field-focus-shadow;
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      border-radius: $border-radius;

      .ng-arrow {
        border-color: transparent transparent $black-100;
      }
    }
  }

  .ng-dropdown-panel {
    font-family: $font-family-primary;
    font-style: normal;
    font-weight: $base-font-weight;
    font-size: calculate-rem(13px);
    margin-top: calculate-rem(4px);
    background: $white;
    border: 1px solid $blue-20;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
    overflow-x: hidden;

    .ng-dropdown-panel-items {
      .ng-option {
        padding-left: calculate-rem(23px);
        padding-right: calculate-rem(23px);

        &.ng-option-marked,
        &.ng-option-selected {
          background-color: $blue-6;
        }

        &.ng-option-disabled {
          font-family: $font-family-primary;
          font-style: normal;
          font-weight: $base-font-weight;
          font-size: calculate-rem(13px);
          color: $black-100;
        }
      }
    }
  }
}

.ng-select.role-ng-select {
  &.ng-select-single {
    .ng-select-container {
      border-radius: 40px;
      background-color: $blue-3;
      border: none;

      .ng-value-container {
        height: 100%;
        display: flex;
        align-items: center;
        @extend .base-select-input;

        padding-left: 15px;
        box-shadow: none;
      }

      .ng-arrow {
        border-color: $black-100 transparent transparent;
      }
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      border-radius: 40px;

      .ng-arrow {
        border-color: transparent transparent $black-100;
      }
    }
  }

  .ng-dropdown-panel {
    @extend .base-dropdown-panel;
  }

  &.ng-select-focused {
    > .ng-select-container {
      border-color: transparent;
    }
  }
}

.ng-select.ng-select-default-size {
  &.ng-select-single {
    .ng-select-container {
      border-radius: 4px;
      background-color: $light-blue-100;
      border: none;
      height: 36px;

      .ng-value-container {
        height: 100%;
        display: flex;
        align-items: center;
        @extend .base-select-input;

        padding-left: 15px;
        box-shadow: none;

        .ng-input {
          display: none;
        }
      }

      .ng-arrow {
        border-color: $blue-3 transparent transparent;
      }
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      border-radius: 4px;

      .ng-arrow {
        border-color: transparent transparent $blue-3;
      }
    }
  }

  .ng-dropdown-panel {
    @extend .base-dropdown-panel;
  }

  &.ng-select-focused {
    > .ng-select-container {
      border-color: transparent;
    }
  }
}

.ng-select.ng-select-middle-size {
  @extend .ng-select-default-size;

  &.ng-select-single .ng-select-container {
    min-height: 30px;
    height: 30px;
  }
}

.ng-select.inline-edit-ng-select {
  @extend .ng-select-default-size;

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    box-shadow: none;
  }

  &.ng-select-single {
    .ng-select-container {
      border-radius: calculate-rem(4px);
      min-height: calculate-rem(26px);
      border: 1px solid #9bb3c1;
      background-color: #fff;

      height: 30px;

      &:hover {
        box-shadow: none;
      }

      .ng-arrow {
        border-color: $blue-100 transparent transparent;
      }
    }

    &.ng-select-opened {
      > .ng-select-container {
        border-radius: calculate-rem(4px);

        .ng-arrow {
          border-color: transparent transparent $blue-60;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      border-radius: calculate-rem(4px);
      min-height: calculate-rem(26px);
      border: 1px solid #9bb3c1;
      height: 30px;

      .ng-value-container {
        line-height: 1.625;

        .ng-value {
          border-radius: calculate-rem(4px);
          background-color: $blue-6;
          white-space: nowrap;

          .ng-value-icon {
            padding: 2px 5px;
            cursor: pointer;

            &:hover {
              border-radius: 0 4px 4px 0;
              background-color: $blue-20;
            }

            &.right {
              border-left: 1px solid $blue-100;
            }
          }
        }
      }

      .ng-arrow {
        border-color: $blue-100 transparent transparent;
      }

      .ng-clear-wrapper {
        color: $blue-40;
        text-align: center;

        &:hover {
          .ng-clear {
            color: $blue-40;
          }
        }

        .ng-clear {
          line-height: unset;
          vertical-align: middle;
        }
      }

      .ng-spinner-loader {
        border-top: 2px solid rgba($light-blue-100, 0.2);
        border-right: 2px solid rgba($light-blue-100, 0.2);
        border-bottom: 2px solid rgba($light-blue-100, 0.2);
        border-left: 2px solid $light-blue-100;
      }
    }

    &.ng-select-opened {
      > .ng-select-container {
        border-radius: calculate-rem(4px);

        .ng-arrow {
          border-color: transparent transparent $blue-60;
        }
      }
    }
  }
}

.ng-select.ng-select-small-size {
  @extend .ng-select-default-size;

  &.ng-select-single .ng-select-container,
  &.ng-select-multiple .ng-select-container {
    min-height: 26px;
    height: 26px;
  }
}

.ng-select.user-table-ng-select {
  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: none;
    box-shadow: none;
  }

  &.ng-select-single {
    .ng-select-container {
      background-color: transparent;
      border: none;

      &:hover {
        box-shadow: none;
      }

      .ng-value-container {
        height: 100%;
        display: flex;
        align-items: center;
        @extend .base-select-input;

        padding: 5px;
        box-shadow: none;

        .ng-value {
          .ng-value-label {
            font-family: $font-family-secondary;
            font-style: normal;
            font-weight: bold;
            font-size: calculate-rem(15px);
            line-height: calculate-rem(18px);
            color: $light-blue-100;
          }
        }
      }

      .ng-arrow {
        border-color: $light-blue-100 transparent transparent;
      }
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      .ng-arrow {
        border-color: transparent transparent $light-blue-100;
      }
    }
  }

  .ng-dropdown-panel {
    @extend .base-dropdown-panel;
  }

  &.ng-select-focused {
    > .ng-select-container {
      border-color: transparent;
    }
  }
}

.ng-select.candidate-custom-field-selection {
  @extend .custom-ng-select;

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {

        &.ng-option-disabled {
          display: none !important;
        }
      }
    }
  }
}

.ng-select.filter-ng-select {
  &.ng-select-multiple {
    .ng-select-container {
      @extend .transparent-select-styles;
    }
  }

  &.ng-select-single {
    .ng-select-container {
      @extend .transparent-select-styles;

      .ng-value-container {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 calculate-rem(24px);
        @extend .transparent-select-input;

        .ng-value-label {
          padding-left: calc(#{$select-input-padding} - 10px);
        }

        .ng-input {
          top: 50%;
          transform: translateY(-50%);
          padding-left: $select-input-padding;

          > input {
            @extend .transparent-select-input;
          }
        }

        .ng-placeholder {
          @extend .transparent-select-input;
        }
      }

      .ng-arrow-wrapper {
        position: absolute;

        .ng-arrow {
          border-color: $white transparent transparent;
        }
      }
    }
  }

  &.ng-select-focused {
    > .ng-select-container {
      border-color: transparent;
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      border-radius: $border-radius;

      .ng-arrow-wrapper {
        position: absolute;

        .ng-arrow {
          border-color: transparent transparent $white;
        }
      }
    }
  }

  .ng-dropdown-panel {
    width: calculate-rem(230px);
    @extend .base-dropdown-panel;
  }

  &.ng-select-disabled > .ng-select-container {
    background-color: $blue-3;
  }
}

.ng-select.sort-ng-select {
  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: none;
    box-shadow: none;
  }

  &.ng-select-single {
    .ng-select-container {
      cursor: pointer;
      background-color: transparent;
      border: none;
      height: calculate-rem(40px);

      &:hover {
        box-shadow: none;
      }

      .ng-value-container {
        color: $blue-100;
        @extend .base-select-input;

        padding: calculate-rem(5px);
        box-shadow: none;

        .ng-value {
          .ng-value-label {
            font-family: $font-family-primary;
            font-style: normal;
            font-weight: bold;
            font-size: calculate-rem(13px);
            line-height: calculate-rem(18px);
          }
        }

        .ng-placeholder {
          color: $blue-50;
          @extend .base-select-input;
        }
      }

      .ng-arrow-wrapper {
        padding-right: 0;
        width: auto;

        .ng-arrow {
          border-color: $blue-100 transparent transparent;
        }
      }
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      .ng-arrow-wrapper {
        .ng-arrow {
          border-color: transparent transparent $blue-100;
        }
      }

    }
  }

  .ng-dropdown-panel {
    right: 0;
    left: auto;
    width: auto;
    @extend .base-dropdown-panel;

    .ng-dropdown-panel-items {
      .ng-option {
        display: flex;
        justify-content: flex-end;
      }

    }
  }

  &.ng-select-focused {
    > .ng-select-container {
      border-color: transparent;
    }
  }
}

.modal-frame {
  .ng-dropdown-panel {
    @extend .base-dropdown-panel;
  }
}
