@import '~theme/functions';
@import '~theme/variables/variables';
@import '~theme/variables/colors';

.normal-text {
  font-family: $font-family-primary;
  font-weight: 600;
  font-size: calculate-rem(13px);
  margin-bottom: 0;
}

.auth-title {
  font-size: calculate-rem(20px) !important;
  font-style: normal;
  font-weight: bold;
  color: $blue-100;
}

.auth-sub-title {
  font-size: calculate-rem(14px) !important;
  font-style: normal;
  font-weight: normal;
  color: $blue-100;
}


.page-header-title {
  font-family: $font-family-secondary;
  font-style: normal;
  font-weight: bold;
  font-size: calculate-rem(19px) !important;
  color: $blue-100;
  margin: 0 calculate-rem(20px);
}

.card-filter-title {
  font-family: $font-family-primary;
  font-weight: 700;
  color: $blue-100;
  font-size: calculate-rem(13px);
  margin-left: calculate-rem(7px);
  margin-bottom: 0;
}

.basic-white-normal-text {
  @extend .normal-text;

  color: $white;
}

.basic-white-bold-text {
  font-family: $font-family-primary;
  font-weight: 700;
  color: $white;
  font-size: calculate-rem(13px);
  margin-bottom: 0;
}

.basic-blue-normal-text {
  @extend .normal-text;

  color: $blue-100;
}

.basic-black-normal-text {
  @extend .normal-text;

  color: $black-100;
}

.basic-green-normal-text {
  @extend .normal-text;

  color: $green-success-100;
}

.basic-red-normal-text {
  @extend .normal-text;

  color: $red-100;
}
